// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kampanje-js": () => import("./../../../src/pages/kampanje.js" /* webpackChunkName: "component---src-pages-kampanje-js" */),
  "component---src-templates-template-order-js": () => import("./../../../src/templates/templateOrder.js" /* webpackChunkName: "component---src-templates-template-order-js" */),
  "component---src-templates-template-packages-js": () => import("./../../../src/templates/templatePackages.js" /* webpackChunkName: "component---src-templates-template-packages-js" */),
  "component---src-templates-template-page-js": () => import("./../../../src/templates/templatePage.js" /* webpackChunkName: "component---src-templates-template-page-js" */),
  "component---src-templates-template-promo-js": () => import("./../../../src/templates/templatePromo.js" /* webpackChunkName: "component---src-templates-template-promo-js" */),
  "component---src-templates-template-team-js": () => import("./../../../src/templates/templateTeam.js" /* webpackChunkName: "component---src-templates-template-team-js" */)
}

